

import React from 'react'
import SHeader from '../components/s_header';
import Header from '../components/header/header';
import Login from '../components/login/login';

const LoginPage = () => {
  return (
    <>
    <Header/>
    <Login/>
    </>
  )
}

export default LoginPage