import { Route, Routes,Navigate } from "react-router-dom";
import React from 'react'
import Questionp from '../components/question_page/questionp'
import LoginPage from "./login";
import Login from "../components/login/login";


const Quesionpage = () => {
  
 
  return (
    <Questionp/>
  )
}

export default Quesionpage