import React, { useState } from 'react';
import { decryptString } from '../components/encryption';
import {storage} from "../../src/firebase"
import {  getDownloadURL, ref,uploadBytesResumable } from "firebase/storage";
const UploadForm = () => {
  const [formData, setFormData] = useState({
    type: 0,
    marks: [],
    ans: [],
    options: [],
    body: '',
    subject: 0,
    paperId: '65cb5ca40e655f0e001a910b'
  });
  const [selectedImage, setSelectedImage] = useState(null)
  const [file, setFile] = useState(null);
 
  const [loading,setloading]=useState(false)

  const handleChange = (e) => {
    let { name, value } = e.target;
    let a=[]
    if(name==="marks"){
      a = value.split(':').map(Number);
      setFormData({ ...formData, [name]: a });
    }else if (name==="ans") {
      a = value.split(':').map(Number);
      setFormData({ ...formData, [name]: a });
    }else if (name==="options") {
      a = value.split(':').map(String);
      setFormData({ ...formData, [name]: a });
    }else{
      setFormData({ ...formData, [name]: value });
    }
    

    
  };


  const handleImageChange =async (event) => {
       
    const file = event.target.files[0];
    setSelectedImage(file)
    if (file && file.type.startsWith('image/')) {
        const imageUrl = URL.createObjectURL(file);
        setFile(imageUrl);
      } else {
        alert('Please select a valid image file.');
      }
  };
  const submit=(imageurl)=>{
    
     
    
    const token = decryptString(localStorage.getItem("jwtToken"))
    fetch(`http://localhost:8000/api/add-question`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
            'AuthGuardPass': process.env.REACT_APP_AUTHGUARD_PASS
        },
        body: JSON.stringify({...formData,imageurl})
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response);
            }
            return response.json();
        })
        .then((responseData) => {
           
            alert(responseData)
        })
        .catch((error) => {
            alert(error);
        });
  }
  const handleUpload = (e) => {
    e.preventDefault()
    if (selectedImage) {
      setloading(true)
      const imageRef = ref(storage,`testpapers/${Date.now()}`)

      const uploadTask = uploadBytesResumable(imageRef,selectedImage)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
         
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         
        },
        (error) => {
         
          console.error('Upload error:', error);
        },
        () => {
         
          
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setloading(false)
          
          submit(downloadURL)
          });
        }
      );
    }else{
      submit()
    }
}

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">
              <h2 className="text-center">Upload Form</h2>
            </div>
            <div className="card-body">
              
              <form onSubmit={handleUpload}>
                <div className="mb-3">
                  <label htmlFor="type" className="form-label">Type:</label>
                  <input type="number" className="form-control" id="type" name="type" value={formData.type} onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="marks" className="form-label">Marks:</label>
                  <input type="text" className="form-control" id="marks" name="marks"  onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="ans" className="form-label">Answer:</label>
                  <input type="text" className="form-control" id="ans" name="ans"  onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="imageurl" className="form-label">Image URL:</label>
                  <input type="file" className="form-control"  onChange={handleImageChange} />
                  <button onClick={handleUpload} className="btn btn-primary" type='none'>{loading?"uplaoding":"Upload"}</button>
                </div>
                <div className="mb-3">
                  <label htmlFor="options" className="form-label">Options:</label>
                  <input type="text" className="form-control" id="options" name="options"  onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label htmlFor="body" className="form-label">Body:</label>
                  <textarea className="form-control" id="body" name="body" value={formData.body} onChange={handleChange}  defaultValue={formData.body}/>
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">Subject:</label>
                  <input type="number" className="form-control" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="paperId" className="form-label">Paper ID:</label>
                  <input type="text" className="form-control" id="paperId" name="paperId" value={formData.paperId} onChange={handleChange} />
                </div> */}
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadForm;
 